.icon.plus {
  background-image: url(#{$blocks-editor-icons-path}/plus.svg);
}

/**
.icon.dropdown-more {
background-image: url(#{$blocks-editor-icons-path}/dropdown-more.svg);
}

.icon.font-color {
  background-image: url(#{$blocks-editor-icons-path}/font-color.svg);
}

.icon.font-family {
  background-image: url(#{$blocks-editor-icons-path}/font-family.svg);
}

.icon.bg-color {
  background-image: url(#{$blocks-editor-icons-path}/bg-color.svg);
}

i.palette {
  background-image: url(#{$blocks-editor-icons-path}/palette.svg);
}

i.bucket {
  background-image: url(#{$blocks-editor-icons-path}/paint-bucket.svg);
}

i.clear {
  background-image: url(#{$blocks-editor-icons-path}/trash.svg);
}

i.image {
  background-image: url(#{$blocks-editor-icons-path}/file-image.svg);
}

i.close {
  background-image: url(#{$blocks-editor-icons-path}/close.svg);
}

i.figma {
  background-image: url(#{$blocks-editor-icons-path}/figma.svg);
}

i.poll {
  background-image: url(#{$blocks-editor-icons-path}/card-checklist.svg);
}

i.indent {
  background-image: url(#{$blocks-editor-icons-path}/indent.svg);
}

i.markdown {
  background-image: url(#{$blocks-editor-icons-path}/markdown.svg);
}

i.outdent {
  background-image: url(#{$blocks-editor-icons-path}/outdent.svg);
}

i.undo {
  background-image: url(#{$blocks-editor-icons-path}/arrow-counterclockwise.svg);
}

i.redo {
  background-image: url(#{$blocks-editor-icons-path}/arrow-clockwise.svg);
}

i.sticky {
  background-image: url(#{$blocks-editor-icons-path}/sticky.svg);
}

i.mic {
  background-image: url(#{$blocks-editor-icons-path}/mic.svg);
}

i.import {
  background-image: url(#{$blocks-editor-icons-path}/upload.svg);
}

i.export {
  background-image: url(#{$blocks-editor-icons-path}/download.svg);
}

i.diagram-2 {
  background-image: url(#{$blocks-editor-icons-path}/diagram-2.svg);
}

i.user {
  background-image: url(#{$blocks-editor-icons-path}/user.svg);
}

i.equation {
  background-image: url(#{$blocks-editor-icons-path}/plus-slash-minus.svg);
}

i.gif {
  background-image: url(#{$blocks-editor-icons-path}/filetype-gif.svg);
}

i.copy {
  background-image: url(#{$blocks-editor-icons-path}/copy.svg);
}

i.success {
  background-image: url(#{$blocks-editor-icons-path}/success.svg);
}

i.prettier {
  background-image: url(#{$blocks-editor-icons-path}/prettier.svg);
}

i.prettier-error {
  background-image: url(#{$blocks-editor-icons-path}/prettier-error.svg);
}
*/
